import React from 'react'

function EduactionBackground() {
  return (
    <section id="edu" class="flex-col px-20 gap-2 items-center w-4/5 mx-auto mt-10">
        <h4 class="mb-8 text-3xl font-bold text-center md:text-left">Education</h4>
        <div class="grid grid-cols-1 md:grid-cols-6 my-10">
            <div class="flex flex-col col-span-2 mb-4 md:mb-0">
                <h5 class="text-xl md:text-2xl  font-bold">B.E. Computer Science</h5>
                
                <p>July 2017 - July 2022</p>
            </div>
            <div class="flex flex-col col-span-4 ">
              <h6 class="text-lg font-bold">University of Malawi</h6>
                <p>Bachelors of Education, Specializing Computer Science</p>
            </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-6 my-10">
            <div class="flex flex-col col-span-2 mb-4 md:mb-0">
                <h5 class="text-xl md:text-2xl  font-bold">M.S.C.E</h5>
                <p>Sept 2012 - July 2016</p>
            </div>
            <div class="flex flex-col col-span-4 ">
                
               <h6 class="text-lg font-bold">Mzuzu Government Secondary School</h6>
                <p>Malawi School Certificate of Education</p>
            </div>
        </div>
    </section>
  )
}

export default EduactionBackground