import React from 'react'
import emailjs from 'emailjs-com';
import {useRef, useState} from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import Footerbar from './Footerbar';

function ContactForm() {
  const form = useRef();
  const [alertVisibility , setAlertVisibility ] = useState(false)
  const pk ='ah5rwsbd7YFWJ_wbQ'
  const sevid= 'service_apgtdi5'
  const temp = 'template_z4rh8kk';

  const sendMeEmail = (e)=>{
    e.preventDefault();

    emailjs.sendForm(`${sevid}`, `${temp}`, form.current, `${pk}`)
      .then((result) => {
        console.log(result.text);
        e.target.reset();
        setAlertVisibility(true);
        
      },
      (error) => {
        console.log(error.text);
        setAlertVisibility(false);
      });
  }

  return (
    <div className='py-10 bg-gray-800 relative items-center'>
      <h3 className="text-4xl font-semibold text-center">
          Send Me a  <span className="text-green-600 ">Message</span>
        </h3>
        <div className="block p-6 rounded-lg shadow-lg shadow-white mx-auto max-w-md">
          <form ref={form} onSubmit={sendMeEmail}>
            {alertVisibility?<p className="text-center text-green-500 md:text-xl text-xs">Message was successfully sent</p>: null}

            <div className="form-group mb-6">
              <label>Name</label>
            
              <input className="form-control block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" type="text" name="user_name"/>
              </div>
              <div className="form-group mb-6">
                <label>Email</label>
                <input type="email" name="user_email" class="form-control block
                  w-full
                  px-3
                  py-1.5
                  text-base
                  font-normal
                  text-gray-700
                  bg-white bg-clip-padding
                  border border-solid border-gray-300
                  rounded
                  transition
                  ease-in-out
                  m-0
                  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"/>
              </div>
              <div className="form-group mb-6">
                <label>Message</label>
                <textarea name="message" row={3}  className="
                  form-control
                  block
                  w-full
                  px-3
                  py-1.5
                  text-base
                  font-normal
                  text-gray-700
                  bg-white bg-clip-padding
                  border border-solid border-gray-300
                  rounded
                  transition
                  ease-in-out
                  m-0
                  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                "/>
              </div>
              <div className="form-group mb-6">
                <input type="submit" value="Send a Message" 
                  className="
                  w-full
                  px-6
                  py-2.5
                  bg-blue-600
                  text-white
                  font-medium
                  text-xs
                  leading-tight
                  uppercase
                  rounded
                  shadow-md
                  hover:bg-blue-700 hover:shadow-lg
                  focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
                  active:bg-blue-800 active:shadow-lg
                  transition
                  duration-150
                  ease-in-out"/>
              </div>
          </form>
        </div>
         <Footerbar/>
      
    </div>
  )
}

export default ContactForm