import React from 'react'
import chesteve from "./components/chesteve.jpg"
function AboutChesteve() {

  const additionme = [
    { text: "Years of Experience", count: "02" },
    { text: "Completed Projects", count: "15" },
    { text: "Companies Work", count: "04" },
  ];
  return (
    <section id="about"  className="-py-3 text-white">
      <div className="text-center md:mt-2 p-2">
            
        <div class="mx-auto w-3/4  bg-gray-800 border-4 border-green-600 rounded-lg md:p-10 p-3 text-center">
          <code className="sm:text-5xl text-4xl font-medium leading-tight mb-5 capitalize  underline -py-16">
              About <span className="text-green-600">Me</span>
              
          </code>   
          <div className="flex">
            <code class="mt-1 md:text-xl text-normal  leading-8 text-white">
              I am a an IT professional with a proven track record in designing websites, software and mobile applications development , and managing database. I am eager to be challenged inorder to grow and further improve my IT skills. As a teacher, I am open-minded, patient and supportive towards other people.
            </code>

          </div>
          <div className="mt-4 items-center md:ml-9 text-center md:gap-36 sm:gap-3  md:w-auto md:flex sm:flex inline-block content-evenly mx-auto px-auto">
            {additionme.map((data) => (
              <div key={data.text}>
                <h3 className="md:text-4xl text-2xl font-semibold text-white active:text-cyan-500">
                  {data.count}
                    <span className="text-green-600">+</span>{" "}
                </h3>
                <span className="md:text-base text-xs sm:mx-auto px-auto">{data.text}</span>
              </div>
             ))}
          </div>
         
        </div>
       

      </div>
    </section>
  )
}

export default AboutChesteve