import React from 'react'

function Footerbar() {
  return (
    <div>
        <hr class="my-6 border-blueGray-300"/>
        <div class="flex flex-wrap items-center md:justify-between justify-center">
          <div class="w-full md:w-4/12 px-4 mx-auto text-center">
            <div class="flex px-auto text-sm text-blueGray-500 font-semibold py-1">
              <p>Copyright © <span > { new Date().getFullYear()}</span></p>
              <a href="mailto: stevenkamwaza@gmail.com" className=" px-4 text-blueGray-500 hover:text-blueGray-800 underline">Steven Kamwaza</a>
            </div>
          </div>
        </div>
    </div>
  )
}

export default Footerbar