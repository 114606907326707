
import './App.css';
import Navbar from './components/Navbar'
import HeadingComp from './components/HeadingComp'
import AboutChesteve from './AboutChesteve';
import Experiences from './Experiences';
import EduactionBackground from './components/EduactionBackground'
import ContactMe from './components/ContactForm';

function App() {
  return (
    <div>
      <Navbar/>
      <HeadingComp/>
      <AboutChesteve/>
      <Experiences/>
      <hr class="border-gray-400 mx-44" />
      <EduactionBackground/>
      <ContactMe/>
    </div>
  );
}

export default App;
